<template>
  <b-container
    fluid
  >
    <b-row class="mb-4">
      <b-col
        cols="12"
      >
        <b-card
          title="Payment Requests"
          sub-title="My Approvals"
          class="shadow-sm"
        >
          <b-row>

            <b-col
              cols="12"
              md="2"
              class="p-2"
            >
              <PaleCard
                label="Pending"
                variant="warning"
                :count="approvals.pending"
                :link="{ name: 'fom.approvals.payment-requests', params: { fom: 'pending' }}"
                :loading="fetching"
              />
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="p-2"
            >
              <PaleCard
                label="Approved"
                variant="success"
                :count="approvals.approved"
                :link="{ name: 'fom.approvals.payment-requests', params: { fom: 'histories', status: 'Approved' }}"
                :loading="fetching"
              />
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="p-2"
            >
              <PaleCard
                label="Disapproved"
                variant="danger"
                :count="approvals.disapproved"
                :link="{ name: 'fom.approvals.payment-requests', params: { fom: 'histories', status: 'Disapproved' }}"
                :loading="fetching"
              />
            </b-col>

          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { FOMDashboardService } from '@/services'
import PaleCard from '@/components/custom/PaleCard'

export default {
  name: 'FomDashboard',

  middleware: ['auth', 'fom'],

  components: {
    PaleCard
  },

  metaInfo: () => ({
    title: 'Dashboard'
  }),

  data () {
    return {
      fetching: false,
      approvals: {
        pending: 0,
        disapproved: 0,
        approved: 0
      }
    }
  },

  mounted () {
    core.index()
    this.getStatistics()
  },

  methods: {

    async getStatistics () {
      this.fetching = true
      return await FOMDashboardService.get().then(({ data }) => {
        this.approvals.pending = data.approver_pending
        this.approvals.disapproved = data.approver_disapproved
        this.approvals.approved = data.approver_approved
        this.fetching = false
      })
    }

  }
}
</script>
